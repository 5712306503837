export type CookiePreference = "all" | "necessary" | "none" | undefined

export function cookiePreferences(): CookiePreference {
  const cookies = document.cookie.split(";")
  const cookiesAccepted = cookies.find(cookie => cookie.includes("cookies_accepted"))

  if (cookiesAccepted) {
    const value = cookiesAccepted.split("=")[1]
    if (value === "all" || value === "necessary" || value === "none") {
      return value
    }
  }
  return undefined
}

export function setCookiePreferences(value: CookiePreference) {

  switch (value) {
    case "all":
      document.cookie = "cookies_accepted=all; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/"
      break
    case "necessary":
      document.cookie = "cookies_accepted=necessary; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/"
      break
    case "none":
      document.cookie = "cookies_accepted=none; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/"
      break
    default:
      console.error("Invalid value for cookie preferences: ", value)
  }
}